export const ChaletModel = {
  id:null,
  insurance: null,
  address: {
    en: null,
    ar: null
  },
  latitude: null,
  longitude: null,
  owner_id: null,
  bedrooms: null,
  frontage: null
}


export const FrontageOptions =[
  {
    text: '',
    value: null,
  },
  {
    text: 'Sea View',
    value: 'sea_view',
  },
  {
    text: 'Second Row',
    value: 'second_row',
  }
]
